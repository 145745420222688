import { Navigate } from 'react-router';
import { clearSession, getSessionInfo } from '../../utilities/helpers';
import { useLocation } from 'react-router-dom';
import CustomThemeWrapper from '../../utilities/customThemeWrapper';

export type ProtectedRouteProps = {
    authenticationPath: string;
    roles?: string[];
    children: JSX.Element
};

export default function ProtectedRoute({ authenticationPath, children }: ProtectedRouteProps) {

    const location = useLocation();

    const { token } = getSessionInfo();
    if(token){

        // if(temp && location.pathname !== '/change-password'){
        //     return <Navigate to={'/change-password'} state={{from: location}}/>
        // }

        return <CustomThemeWrapper>{children}</CustomThemeWrapper>;
    }
    else {
        clearSession();
        return <Navigate to={authenticationPath} state={{from: location}}/>
    }


   
};