import { NavigateFunction } from "react-router-dom";
import { ActivityLabels } from "./activityConstants";
import { S3_ROOT } from "./const";
import moment from "moment";

export const clearSession = () => {
  sessionStorage.clear();
};

export const isTokenPresent = () => {
  return Boolean(sessionStorage.getItem("token"));
};

export const getSessionInfo = () => {
  const token = sessionStorage.getItem("token");
  const temp = sessionStorage.getItem("temp");

  return { token, temp };
};

export const getActivities = () => {
  return ActivityLabels.map((a, i) => ({
    title: a.label,
    description: "",
    image: `${S3_ROOT}/assets/activity-icons/${a.type.toLowerCase()}.png`,
    id: i + 1,
  }));
};

export const capitalizeFirstLetter = (s: string) => {
  const words = s.toLowerCase().split("_");

  return words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");
};

export const toDate = (dateStr: string) => {
  const [year, month, day] = dateStr.split("-");
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
};

export const formatDate = (date: Date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const logoutSuccess = (navigate: NavigateFunction) => {
  return () => {
    clearSession();
    navigate("/");
  };
};

export const toDateString = (dateStr: string | Date) => {
  return moment(dateStr, "YYYY-MM-DD").format("Do MMM YYYY");
};

export const toDateStringMonthAndYear = (dateStr: string | Date) => {
  return moment(dateStr, "YYYY-MM-DD").format("DD/MM/YYYY") || moment().format("DD/MM/YYYY");
};

export const currentDateToDBString = () => {
  return moment().format("YYYY-MM-DD");
};

export const getBase64 = (file: File, cb: (img: any) => void) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
};

export const formatDateSlash = (date: Date) => {
  return moment(date).format("DD/MM/YY");
};

export const getCreatedTime = (date: string) => {
  let today = new Date();
  let diffMs = today.valueOf() - new Date(date).valueOf();
  let diffDays = Math.floor(diffMs / 86400000); // days
  let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
  let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
  return diffDays > 0
    ? diffDays + " days ago"
    : diffHrs > 0
    ? diffHrs + " hrs ago"
    : diffMins + "mins ago";
};

export const getDaysAgo = (date: string) => {
  let today = new Date();
  let diffMs = today.valueOf() - new Date(date).valueOf();
  let diffDays = Math.floor(diffMs / 86400000); // days

  return diffDays <= 0
    ? "today"
    : diffDays <= 1
    ? "yesterday"
    : diffDays + " days ago";
};

export const DOTFORMAT = "DD/MM/YYYY";
export const MONTHYEARFORMAT = "MMMM YYYY";

const HOURS_IN_A_DAY = 24;
const MINUTES_IN_AN_HOUR = 60;

function convertDaysToHours(days: number) {
  return days * HOURS_IN_A_DAY;
}

function convertHoursToMinutes(hours: number) {
  return hours * MINUTES_IN_AN_HOUR;
}

export function formatDuration(durationMinutes: number) {
  const duration = moment.duration(durationMinutes, "minutes");

  const days = Math.max(Math.floor(duration.asDays()), 0);
  const daysInHours = convertDaysToHours(days);
  const hours = Math.max(Math.floor(duration.asHours() - daysInHours), 0);
  const daysInMinutes = convertHoursToMinutes(daysInHours);
  const hoursInMinutes = convertHoursToMinutes(hours);
  const minutes = Math.max(
    Math.round(duration.asMinutes() - daysInMinutes - hoursInMinutes),
    0
  );

  const formattedDays = days !== 1 ? `${days} days` : `${days} day`;
  const formattedHours = hours !== 1 ? `${hours} hrs` : `${hours} hr`;
  const formattedMinutes = minutes !== 1 ? `${minutes} mins` : `${minutes} min`;
  if (days && hours && minutes) {
    return `${formattedDays} ${formattedHours} ${formattedMinutes}`;
  }

  if (days && hours) {
    return `${formattedDays} ${formattedHours}`;
  }

  if (hours && minutes) {
    return `${formattedHours} ${formattedMinutes}`;
  }

  if (days && minutes) {
    return `${formattedDays} ${formattedMinutes}`;
  }

  if (days) {
    return formattedDays;
  }

  if (hours) {
    return formattedHours;
  }

  return formattedMinutes;
}
