import Arrow from '../../assets/images/arrow.svg';
import { fontSizes, fontWeight } from '../../utilities/styleConfig';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { useTheme } from "@mui/material/styles";


interface HeaderProps {
    title: string;
    icon: boolean;
    goBack: () => void;
}

const Header = (headerProps: HeaderProps) => {
    const theme = useTheme();
    const useStyles = makeStyles(() => ({
        container: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 20
        },
        container2: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 20
        },
        title: {
            color: theme.palette.primary.main,
            fontWeight: fontWeight._900,
            fontSize: fontSizes._36,
            ['@media (max-width:970px)']: {
                fontSize: fontSizes._24,
            },
            ['@media (max-width:670px)']: {
                fontSize: fontSizes._20,
            },
        },
        icon: {
            height: '20px',
            marginRight: 20,
            opacity: 0.6
        },
    }));
    const classes = useStyles();
    const { title, icon, goBack } = headerProps;
    return (
        <div className={icon ? classes.container : title === 'Sign In' ? classes.container : classes.container2}>{icon && <a onClick={goBack}><img className={classes.icon} alt="arrow" src={Arrow} /></a>}<Typography className={classes.title}>{title}</Typography></div>
    );
};

export default Header;
