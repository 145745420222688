import logo from "../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import { fetchSchoolBrand } from "../../services/user";
import { useQuery } from "react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { fontWeight } from "../../utilities/styleConfig";

const Logo = () => {
  const navigate = useNavigate();

  const navigateToScreen = () => {
    navigate("/");
  };

  const { data: schoolBrand } = useQuery<any, AxiosError, any>(
    "schoolBrand",
    () => fetchSchoolBrand()
  );

  const [brandLogo, setBrandLogo] = useState(null);

  useEffect(() => {
    if (schoolBrand?.brand_logo) {
      setBrandLogo(schoolBrand.brand_logo);
    }
  }, [schoolBrand]);

  return brandLogo ? (
    <Grid style={{ position: "absolute", top: 20, left: 32 }}>
      <img
        alt="logo"
        src={brandLogo}
        style={{
          height: "8vmin",
          cursor: "pointer",
        }}
        onClick={navigateToScreen}
      />
      <Typography
        style={{
          fontSize: "1.5vmin",
          textAlign: "center",
          lineHeight: 1,
          margin: 0,
        }}
      >
        Powered by{" "}
        <span style={{ color: "#F4511E", fontWeight: fontWeight._600 }}>
          miMove
        </span>
      </Typography>
    </Grid>
  ) : (
    <img
      alt="logo"
      src={logo}
      style={{
        height: "4vmin",
        position: "absolute",
        top: 20,
        left: 32,
        cursor: "pointer",
      }}
      onClick={navigateToScreen}
    />
  );
};

export default Logo;
