export const OTHER_ACTIVITY = "OTHER";

export const ActivitiesNames = [
  { type: "ACTIVE_PLAY", label: "Active play" },
  { type: "ACTIVE_TRAVEL", label: "Active travel" },
  { type: "AMERICAN_FOOTBALL", label: "American Football" },
  { type: "BADMINTON", label: "Badminton" },
  { type: "BALLET", label: "Ballet" },
  { type: "BASEBALL", label: "Baseball" },
  { type: "BASKETBALL", label: "Basketball" },
  { type: "BMX", label: "BMX" },
  { type: "BOXING", label: "Boxing" },
  { type: "CHEERLEADING", label: "Cheerleading" },
  { type: "CLIMBING", label: "Climbing" },
  { type: "CRICKET", label: "Cricket" },
  { type: "CYCLING", label: "Cycling" },
  { type: "DIVING", label: "Diving" },
  { type: "EQUESTRIAN", label: "Equestrian" },
  { type: "EXERCISE_CLASS", label: "Exercise Class" },
  { type: "FENCING", label: "Fencing" },
  { type: "FITNESS_TRAINING", label: "Fitness Training" },
  { type: "FOOTBALL", label: "Football" },
  { type: "GO_KARTING", label: "Go Karting" },
  { type: "GOLF", label: "Golf" },
  { type: "GYMNASTICS", label: "Gymnastics" },
  { type: "HANDBALL", label: "Handball" },
  { type: "HIKING", label: "Hiking" },
  { type: "HOCKEY", label: "Hockey" },
  { type: "HURLING", label: "Hurling" },
  { type: "ICE_SKATING", label: "Ice Skating" },
  { type: "JUDO", label: "Judo" },
  { type: "KARATE", label: "Karate" },
  { type: "KAYAKING", label: "Kayaking" },
  { type: "KINBALL", label: "Kinball" },
  { type: "LACROSSE", label: "Lacrosse" },
  { type: "LAWN_BOWLS", label: "Lawn Bowls" },
  { type: "NETBALL", label: "Netball" },
  { type: "NITRO_BALL", label: "Nitro Ball" },
  { type: "OTHER_DANCE", label: "Other Dance" },
  { type: "OTHER_MARTIAL_ART", label: "Other Martial Art" },
  { type: "PADDLE_BOARDING", label: "Paddle Boarding" },
  { type: "PARKOUR", label: "Parkour" },
  { type: "PHYSICALLY_ACTIVE_LEARNING", label: "Physically Active Learning" },
  { type: "PICKLE_BALL", label: "Pickle Ball" },
  { type: "ROLLER_OR_INLINE_SKATING", label: "Skating" },
  { type: "ROUNDERS", label: "Rounders" },
  { type: "ROWING", label: "Rowing Outdoors" },
  { type: "ROWING_INDOORS", label: "Rowing Indoors" },
  { type: "RUGBY", label: "Rugby" },
  { type: "RUNNING", label: "Running" },
  { type: "RUNNING_TREADMILL", label: "Running Treadmill" },
  { type: "SAILING", label: "Sailing" },
  { type: "SKATEBOARDING", label: "Skateboarding" },
  { type: "SKIING", label: "Skiing" },
  { type: "SNOOKER", label: "Snooker" },
  { type: "SNOWBOARDING", label: "Snowboarding" },
  { type: "SOFTBALL", label: "Softball" },
  { type: "SPEED_SKATING", label: "Speed Skating" },
  { type: "SPIKE_BALL", label: "Spike Ball" },
  { type: "SQUASH", label: "Squash" },
  { type: "STREET_DANCE", label: "Street Dance" },
  { type: "SURFING", label: "Surfing" },
  { type: "SWIMMING", label: "Swimming" },
  { type: "TABLE_TENNIS", label: "Table Tennis" },
  { type: "TEN_PIN_BOWLING", label: "Ten Pin Bowling" },
  { type: "TENNIS", label: "Tennis" },
  { type: "TRACK_AND_FIELD_ATHLETICS", label: "Track and Field Athletics" },
  { type: "TRAMPOLINING", label: "Trampolining" },
  { type: "VOLLEYBALL", label: "Volleyball" },
  { type: "WALKING_HIKING", label: "Walking/Hiking" },
  { type: "WALKING", label: "Walking" },
  { type: "WATER_POLO", label: "Water Polo" },
  { type: "WIND_SURFING", label: "Wind Surfing" },
  { type: "WRESTLING", label: "Wrestling" },
  { type: "YOGA", label: "Yoga" },
  { type: OTHER_ACTIVITY, label: "Other - Please State" },
];

export const ActivityLabels = ActivitiesNames.filter(
  (item) => item.type !== OTHER_ACTIVITY && item.type !== "WALKING_HIKING"
).concat([{ type: OTHER_ACTIVITY, label: "Other" }]);

export const getActivityLabel = (type: string, other_name?: string) => {
  const activity = ActivityLabels.find((item) => item.type === type);
  if (activity?.label == "Other") {
    return activity.label + " - " + other_name;
  }

  return activity?.label ? activity.label : "";
};

export const WithWhomChoices = [
  { type: "ON_MY_OWN", label: "On my own" },
  { type: "WITH_MIFRIENDS", label: "With miFriend(s)" },
  { type: "WITH_FRIENDS", label: "With friend(s)" },
  { type: "WITH_FAMILY_MEMBERS", label: "With family member(s)" },
];

export const InsideSchoolLocations = [
  { type: "INSIDE_SCHOOL_PE", label: "PE" },
  { type: "INSIDE_SCHOOL_CLUB", label: "Club" },
  { type: "INSIDE_SCHOOL_BREAK", label: "Breaktime" },
];

export const OutsideSchoolLocation = [
  { type: "OUTSIDE_SCHOOL_CLUB", label: "Club or Class" },
  { type: "OUTSIDE_SCHOOL_FREE_PLAY", label: "Free Time" },
  { type: "OUTSIDE_SCHOOL_HOLIDAY_CAMP", label: "Holiday Camp" },
];

export const OutsideLocation = [
  { type: "OUTSIDE_SCHOOL_CLUB", label: "Club or Class" },
  { type: "OUTSIDE_SCHOOL_FREE_PLAY", label: "Free Time" },
];

export const getActivityLocationLabel = (type: string) => {
  const location = [...InsideSchoolLocations, ...OutsideSchoolLocation].find(
    (item) => item.type === type
  );
  return location?.label;
};

export const ActivityTimeOptions = [
  { duration: 15, label: "15 mins" },
  { duration: 30, label: "30 mins" },
  { duration: 45, label: "45 mins" },
  { duration: 60, label: "1 hour" },
  { duration: 75, label: "1 hour 15 mins" },
  { duration: 90, label: "1 hour 30 mins" },
  { duration: 105, label: "1 hour 45 mins" },
  { duration: 120, label: "2 hours" },
  { duration: -1, label: "Custom" },
];

export const getActivityTimeLabel = (duration: number) => {
  const timeOption = ActivityTimeOptions.find(
    (item) => item.duration === duration
  );
  if (!timeOption) {
    let hour = Math.floor(duration / 60);
    let minutes = duration % 60;
    return `${hour === 1 ? `${hour} hour` : `${hour} hours`} ${
      minutes === 1 ? `${minutes} min` : `${minutes} mins`
    }`;
  }
  return timeOption?.label;
};

export const ActivityFeelingsOptions = [
  { type: "GREAT", label: "Great" },
  { type: "GOOD", label: "Good" },
  { type: "OK", label: "OK" },
  { type: "NOT_GOOD", label: "Not Good" },
  { type: "HORRIBLE", label: "Horrible" },
];

export const getActivityFeelingLabel = (feeling: string) => {
  const timeOption = ActivityFeelingsOptions.find(
    (item) => item.type === feeling
  );
  return timeOption?.label;
};

// types
export interface IActivityTimeOptions {
  duration: number;
  label: string;
}
export interface IActivitiesGeneric {
  type: string;
  label: string;
}

export const How = [
  { type: "WALK", label: "Walk" },
  { type: "BIKE", label: "Bike" },
  { type: "SCOOTER", label: "Scooter" },
  { type: "SKATEBOARD", label: "Skateboard" },
  { type: "OTHER", label: "Other" },
];

export const HourArr = [
  { type: 0, label: "0 hours" },
  { type: 1, label: "1 hour" },
  { type: 2, label: "2 hours" },
  // { type: 3, label: "3 hours" },
];

export const Minutes = [
  { type: 0, label: "0 mins" },
  { type: 1, label: "1 min" },
  { type: 2, label: "2 mins" },
  { type: 3, label: "3 mins" },
  { type: 4, label: "4 mins" },
  { type: 5, label: "5 mins" },
  { type: 6, label: "6 mins" },
  { type: 7, label: "7 mins" },
  { type: 8, label: "8 mins" },
  { type: 9, label: "9 mins" },
  { type: 10, label: "10 mins" },
  { type: 11, label: "11 mins" },
  { type: 12, label: "12 mins" },
  { type: 13, label: "13 mins" },
  { type: 14, label: "14 mins" },
  { type: 15, label: "15 mins" },
  { type: 16, label: "16 mins" },
  { type: 17, label: "17 mins" },
  { type: 18, label: "18 mins" },
  { type: 19, label: "19 mins" },
  { type: 20, label: "20 mins" },
  { type: 21, label: "21 mins" },
  { type: 22, label: "22 mins" },
  { type: 23, label: "23 mins" },
  { type: 24, label: "24 mins" },
  { type: 25, label: "25 mins" },
  { type: 26, label: "26 mins" },
  { type: 27, label: "27 mins" },
  { type: 28, label: "28 mins" },
  { type: 29, label: "29 mins" },
  { type: 30, label: "30 mins" },
  { type: 31, label: "31 mins" },
  { type: 32, label: "32 mins" },
  { type: 33, label: "33 mins" },
  { type: 34, label: "34 mins" },
  { type: 35, label: "35 mins" },
  { type: 36, label: "36 mins" },
  { type: 37, label: "37 mins" },
  { type: 38, label: "38 mins" },
  { type: 39, label: "39 mins" },
  { type: 40, label: "40 mins" },
  { type: 41, label: "41 mins" },
  { type: 42, label: "42 mins" },
  { type: 43, label: "43 mins" },
  { type: 44, label: "44 mins" },
  { type: 45, label: "45 mins" },
  { type: 46, label: "46 mins" },
  { type: 47, label: "47 mins" },
  { type: 48, label: "48 mins" },
  { type: 49, label: "49 mins" },
  { type: 50, label: "50 mins" },
  { type: 51, label: "51 mins" },
  { type: 52, label: "52 mins" },
  { type: 53, label: "53 mins" },
  { type: 54, label: "54 mins" },
  { type: 55, label: "55 mins" },
  { type: 56, label: "56 mins" },
  { type: 57, label: "57 mins" },
  { type: 58, label: "58 mins" },
  { type: 59, label: "59 mins" },
];

export const DATE_OF_ACTIVITY_INDEX = 7;
export const EXTRA_MESSAGE_ACTIVITY_INDEX = 6;
export const PHOTO_INDEX = 5;
export const PREVIEW_INDEX = 9;
export const OTHER_ACTIVITY_INDEX = 8;
export const VIDEO_INDEX = 10;
export const PREVIEW_VIDEO_INDEX = 11;
export const TAGGED_INDEX = 12;
