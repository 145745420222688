import Header from '../../utils/header';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { colors, fontSizes, fontWeight } from '../../../utilities/styleConfig';
import * as Yup from 'yup';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { setPassword, SetPasswordFormValues } from '../../../services/user';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import MessageModal from '../../screens/MessageModal';
import closeIcon from '../../../assets/images/close.svg';
import { withFormik, FormikProps } from 'formik';
import { AxiosError } from 'axios';
import React from 'react';
import Logo from '../../common/Logo';
import LoadingButton from '@mui/lab/LoadingButton';

const useStyles = makeStyles(() => ({
    instructions: {
        color: '#4D4D4D',
        fontSize: fontSizes._14,
    },
    rules: {
        paddingLeft: 25,
        fontWeight: fontWeight._700,
    },
    list: {
        padding: 10,
    },
    listItem: {
        padding: "6px 0px",
    },
    content: {
        margin: '2% 0%',
    },
    textField: {
        width: '90%',
        margin: "15px 5px",
        color: "#9E9E9E",
        fontSize: fontSizes._14,
        height: 50,
        backgroundColor: colors.WHITE,
    },
    btn: {
        borderRadius: 70,
        fontSize: fontSizes._16,
        fontWeight: fontWeight._900,
        height: 44,
        width: "90%",
        margin: '5% 0'
    },
    listMenu: {
        ['@media (max-width:970px)']: {
            padding: 15,
        },
    },
    container: {
        backgroundColor: '#F9FDFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        padding: "7% 18%"
    },
  
}));
interface SetPasswordFormProps {
    onSetPassword?: (val: SetPasswordFormValues) => void;
    goBack: () => void;
    username?: string;
    token?: string;
    isLoading: boolean;

}


const SetPasswordForm = (props: FormikProps<SetPasswordFormValues> & SetPasswordFormProps) => {
    const classes = useStyles();
    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        submitForm,
        isValid,
        dirty,
        goBack,
        isLoading

    } = props;


    return (
        <div className={classes.container}>
            <Logo />
                <Header icon={true} title={'Change Password'} goBack={goBack} />
                <Grid container spacing={2} columns={16} className={classes.content}>
                    <Grid item xs={16} lg={7}>

                        <TextField className={classes.textField} type="password" id="new_password1"
                            label="New Password" variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.new_password1}
                            helperText={touched.new_password1 ? errors.new_password1 : ''}
                            error={touched.new_password1 && Boolean(errors.new_password1)} />

                        <TextField className={classes.textField} type="password" id="new_password2"
                            label="Re-Enter New Password" variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.new_password2}
                            helperText={touched.new_password2 ? errors.new_password2 : ''}
                            error={touched.new_password2 && Boolean(errors.new_password2)} />

                        <LoadingButton className={classes.btn}
                            disabled={!(isValid && dirty)}
                            loading={isLoading}
                            variant="contained" onClick={submitForm}>Change Password</LoadingButton>
                    </Grid>

                    <Grid item xs={16} lg={9} className={classes.instructions}>
                        <Typography className={classes.rules}>Rules for creating a strong password</Typography>
                        <ol className={classes.listMenu}>
                            <li className={classes.listItem}><Typography>At least 8 characters (the more the better)</Typography></li>
                            <li className={classes.listItem}><Typography>A mixture of upper & lower case letters and numbers.</Typography></li>
                            <li className={classes.listItem}><Typography>Must be different to username/first name/last name.</Typography></li>
                            <li className={classes.listItem}><Typography>Avoid using common words. Inculde at least one special character (e.g ! @?#))</Typography></li>
                            <li className={classes.listItem}><Typography>Do not copy and paste</Typography></li>
                        </ol>
                    </Grid>
                </Grid>
            </div>
    );
};


const SetPassword = () => {

    const { mutate: SetPasswordMutation, isError, reset, error, isLoading } = useMutation<unknown, AxiosError, SetPasswordFormValues>(setPassword);
    const [message, setMessage] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);

    const { state } = useLocation();
    const { username, token } = state || {};

    const navigate = useNavigate();

    //if they directly land here send them back
    React.useEffect(() => {
        if (!state) {
            navigate('/home');
        }
    }, [state]);

    const handleSetPassword = (payload: SetPasswordFormValues) => {
        SetPasswordMutation(payload, {
            onSuccess: () => {
                setOpen(true);
                setMessage('Your password has been change successfully. Please login again.')
       
            },

        })
    }

    const goBack = () => {
        navigate('/');
    }


    useEffect(() => {

        if (isError) {
            const { new_password1, new_password2, token } = error?.response?.data;

            const errorMessage = token ? token[0] : new_password1 ? new_password1[0] : new_password2 ? new_password2[0] : 'Something went wrong!';
            setMessage(errorMessage);
            setOpen(true);

        }

    }, [isError]);




    const SetPasswordFormWrapper = withFormik<SetPasswordFormProps, SetPasswordFormValues>({
        mapPropsToValues: (props) => {
            const { username, token } = props;

            let mutableUsername = username || '';
            let mutableToken = token || '';

            return {

                new_password1: '',
                new_password2: '',
                username: mutableUsername,
                token: mutableToken


            }
        },


        validationSchema: Yup.object().shape({

            new_password1: Yup.string().required('Password Required'),
            new_password2: Yup.string()
                .required('Confirm your password')
                .oneOf([Yup.ref('new_password1')], 'Password does not match'),
        }),

        handleSubmit(values: SetPasswordFormValues, { props }) {

            const { onSetPassword } = props;
            if (onSetPassword) {
                onSetPassword(values);
            }
        },
    })(SetPasswordForm);

    return <><MessageModal type={isError ? 'error' : 'success'} description={message} title={'Set Password'}
        open={open} handleClose={() => {
            reset();
            setMessage('');
            setOpen(false);
            if(!isError){
                navigate('/');
            }

        }} icon={closeIcon} btnString={isError ? 'Try again' : 'Login'}></MessageModal>
        <SetPasswordFormWrapper isLoading={isLoading} goBack={goBack} username={username} token={token} onSetPassword={handleSetPassword} /></>;
}



export default SetPassword;