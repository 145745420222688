import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SubmitIcon from '../../assets/images/submit.svg';
import ErrorIcon from '../../assets/images/error.svg';
import Emoticon from '../../assets/images/emoticon.svg';
import { colors, fontSizes, fontWeight } from '../../utilities/styleConfig';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
    container: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFFFFF',
        borderRadius: 8,
        boxShadow: "rgb(0 0 0 / 5%) 0px 4px 7px",
        border: '1px solid #CCCCCC',
        padding: "5% 8% 5% 8%",
    },
    content: {
        textAlign: 'center',
    },
    closeIcon: {
        position: 'absolute',
        top: 10,
        right: 10,
        height: '4vmin',
        float: 'right',
        opacity: 0.6,
    },
    title: {
        fontWeight: fontWeight._900,
        fontSize: fontSizes._36,
        color: colors.BLACK
    },
    description: {
        fontSize: fontSizes._16,
        margin: '5% 0%'
    },
    btn: {
        width: '30ch',
        borderRadius: 70,
        fontWeight: fontWeight._900,
        fontSize: fontSizes._16
    }
}));

interface Props {
    description: string;
    title: string;
    btnString: string;
    icon: any;
    type: string;
    handleClose: () => void;
};

interface Props {
    open: boolean;
    description: string;
    title: string;
    type: string;
    btnString: string;
    icon: any,
    handleClose: () => void;
};

const ModalComponent = (props: Props) => {
    const classes = useStyles();
    const { type, open, handleClose, title, description, btnString, icon } = props;

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className={classes.container}>
                <img alt="close" src={icon} className={classes.closeIcon} onClick={handleClose} />
                <Box >
                    <div className={classes.content}>
                        <img alt="submit" src={type === 'success' ? SubmitIcon : type === 'error' ? ErrorIcon : Emoticon} style={{ height: '11vmin' }} />
                        <Typography className={classes.title}>{title}</Typography>
                        {
                            description === 'addedActivity' ? <div><Typography className={classes.description}>Your activity has been recorded<br/>
                                Whatever you do  -<b> miMove</b> it!  </Typography></div>
                                :
                                <div><Typography className={classes.description}>{`${description}`}</Typography></div>
                        }
                        <Button className={classes.btn} variant="contained" onClick={handleClose}>{btnString}</Button>
                    </div>
                </Box>
            </div>
        </Modal>
    );
};

export default ModalComponent;
