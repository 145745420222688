const colors = {
    PRIMARY_DARK: '#0F212E',
    WHITE: '#FFFFFF',
    BLACK: '#000000',
    LIGHT_WHITE: '#F2F7FA',
    PRIMARY_BTN: '#3E62AC',
    PRIMARY_TEXT_COLOR: '#3E62AC'
};

const fontSizes = {
    _10: 10,
    _11: 11,
    _12: 12,
    _13: 13,
    _14: 14,
    _15: 15,
    _16: 16,
    _18: 18,
    _20: 20,
    _22: 22,
    _24: 24,
    _26: 26,
    _30: 30,
    _36: 36,
    _60: 60,
    _100: 100,
};

const fontWeight = {
    _400: 400,
    _600: 600,
    _700: 700,
    _800: 800,
    _900: 900,
};

export { colors, fontSizes, fontWeight };
