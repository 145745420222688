import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { fetchSchoolBrand } from "../services/user";
import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";

export type CustomThemeWrapperProps = {
  children: JSX.Element;
};

const CustomThemeWrapper = ({ children }: CustomThemeWrapperProps) => {
  const { data: schoolBrand } = useQuery<any, AxiosError, any>(
    "schoolBrand",
    () => fetchSchoolBrand()
  );

  const [colors, setColors] = useState({
    primary: "#F4511E",
    secondary: "#FFFFFF",
    background_dark: "#E1F5FE",
    background_light: "#F9FDFF",
    text: "#000000",
  });

  const [footer, setFooter] = useState({
    logo: null,
    content: null,
  });

  useEffect(() => {
    if (schoolBrand?.brand_colors) {
      // setColors({
      //   primary: "#F4511E",
      //   secondary: "#FFFFFF",
      //   background_dark: "#E1F5FE",
      //   background_light: "#F9FDFF",
      //   text: "#000000",
      // })
      let colors = JSON.parse(schoolBrand.brand_colors).light;
      setColors(colors);
    }
    if (schoolBrand?.footer_logo || schoolBrand?.footer_payload) {
      setFooter({
        logo: schoolBrand.footer_logo || null,
        content: schoolBrand?.footer_payload || null,
      });
    }
  }, [schoolBrand]);

  // console.log("schoolBrand", schoolBrand, colors);

  const theme = createTheme({
    palette: {
      primary: {
        main: colors?.primary || "#F4511E",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: colors?.secondary || "#FFFFFF",
        contrastText: colors?.text || undefined,
      },
      error: {
        main: colors?.primary || "#F4511E",
      },
      info: {
        main: colors?.background_dark || "#E1F5FE",
        light: colors?.background_light || "#F9FDFF",
        contrastText: colors?.text || "#424242",
      },
    },
    typography: {
      fontFamily: ["Nunito", "sans-serif"].join(","),
      button: {
        textTransform: "none",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      {children}
      {footer.logo || footer.content ? (
        <Grid
          // position={"fixed"}
          zIndex={1}
          sx={{
            backgroundColor: theme.palette.info.light,
            color: theme.palette.info.contrastText,
          }}
        >
          <Grid>
            <Grid
              container
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              margin={"1rem"}
            >
              {footer.logo && (
                <Grid item margin={"1rem"}>
                  <img
                    alt="Footer Logo"
                    src={footer.logo}
                    style={{
                      height: "8vmin",
                    }}
                  ></img>
                </Grid>
              )}
              {footer.content && (
                <Grid item xs={5} md={7} marginBottom={"1rem"}>
                  <Typography textAlign={"center"} color={"black"}>
                    {footer.content}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </ThemeProvider>
  );
};

export default CustomThemeWrapper;
