import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#F4511E",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FFFFFF",
      contrastText: " #757575",
    },
    error: {
      main: "#F4511E",
    },
  },
  typography: {
    fontFamily: ["Nunito", "sans-serif"].join(","),
    button: {
      textTransform: "none",
    },
  },
});

export default responsiveFontSizes(theme);
